<template>
  <div class="book-detail">
    <p class="title">质量环境一体化管理体系</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书是最早提及管理体系一体化的书籍，凭借笔者多年辅导企业的经验，观察到企业的潜在需求，为了更高效的运行多个管理体系，一体化体系是必然的结果，因此本书讲述了一体化体系的建立原则和案例研究。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。</span
      >
      <br />
      <br />
      <span>
        自1996年9月国际标准化组织发布ISO14001以来，其与ISO9001的关系就引起了许多人士的兴趣，大家都认为二者之间存在着一体化的条件，不应将它们割裂开来。
        但在具体实施时，绝大多数企业并未将ISO14001和ISO9001相联系，而是分别建立质量管理体系和环境管理体系。究其原因，不外乎四方面：
      </span>
      <div style="font-size: 13.5px; line-height: 25px; color: #5a5a5a;padding-left: 50px;">
        <p>1 没有认识到二者之间的有机关联，找不出一体化的途径；</p>
        <p>
          2
          受中国特殊国情的制约（ISO9001和ISO14001分属不同的部门管理，有不同的认证机构），被错误观念误导；
        </p>
        <p>3 通过ISO14001认证仅仅是为了市场宣传的需要，其实并未真正执行；</p>
        <p>4 受辅导的咨询老师水平和经验的限制。</p>
      </div>
      <span
        >事实上，国际上的知名企业很少将ISO14001标准下的环境管理体系独立于其他体系之外，他们非常重视一体化，更多着眼于体系的有效性、充分性和适应性。随着中国走向世界贸易组织（WTO），企业面临着越来越多的体系要求，如果分别建立各式各样的管理体系，将会增加多少管理负担和浪费多少资源！</span
      >
      <br />
      <span
        >毫无疑问，企业经营的目的是追求经济利益。因此，任何管理体系不能无视这一前提。正因为如此，建立ISO9001/ISO14001一体化管理体系的出发点应考虑企业自身利益。只有将质量管理、环境管理和企业的发展紧密联系在一起，才可能使国际标准化组织制定ISO9001和ISO14001标准的初衷得以实现。本书作者在这种认识的基础上进行了研究和实践，也得出许多可供借鉴的经验，作为这种尝试的总结之一，我们将本书奉献给读者，但愿能提供一些有价值的参考。</span
      >
    </div>
  </div>
</template>
    
    <script>
export default {};
</script>
    
    <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>